.sele_pru{
  padding: 0 !important
}
/* input[type="text"]:focus,input[type="number"]:focus, textarea:focus, select:focus,.select2-container-active{
	color: #da0404 !important;
	font-weight: 400 !important;
} */
.my-swal {
  z-index: 999999 !important;
}
input, textarea{
	text-transform: uppercase !important;
}
.tabla-comisiones .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background: #5DBAFF !important;
}
/**button:focus  {
	outline: none !important;
	box-shadow: 0 0 0 5px rgba(21, 156, 228, 0.4) !important;
}**/

.highlight1 {
	background-color: #ffd8d2 !important;
	/* color: #FFF !important; 
  font-size: 12px !important;*/
}
.highlight2 {
	background-color: #ffd8d2 !important;
	/* color: #FFF !important; 
  font-size: 12px !important;*/
}
table.MuiTable-root tbody tr:hover{
	background-color: #ffd8d2 !important;
}